// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBVI5iFuyJLelRTsKbYzK7lyhx3w6YR5GU",
  authDomain: "cryp-2c291.firebaseapp.com",
  projectId: "cryp-2c291",
  storageBucket: "cryp-2c291.appspot.com",
  messagingSenderId: "1046362396293",
  appId: "1:1046362396293:web:e4568d8255f0837ab77bec",
  measurementId: "G-CWXWW4JJXB"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
// const analytics = getAnalytics(app);

export { db };
