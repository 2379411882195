import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { InitalPage } from "./screens/InitalPage";


const router = createBrowserRouter([
  {
    path: "/*",
    element: <InitalPage />,
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
