// utils/validation.js

// 验证用户名
export const isValidUsername = (username) => {
  if (!/^[a-zA-Z0-9]+$/.test(username)) {
    return { isValid: false, message: "Username should contain only letters and digits." };
  }
  if (username.length < 3 || username.length > 20) {
    return { isValid: false, message: "Username should be between 3 and 20 characters long." };
  }
  return { isValid: true, message: "" };
};


// Validate Solana wallet address
export const isValidWalletAddress = (walletAddress) => {
  const base58Regex = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
  return base58Regex.test(walletAddress);
};
