import React, { useState , useEffect } from 'react';
import { useWindowWidth } from "../../breakpoints";
import { ClaimYourGoo } from "../../components/ClaimYourGoo";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { getUsername, registerUser, checkUsernameExists}  from "../../utils/auth";
import { isValidUsername} from "../../utils/validation";  
import twitterIcon from './twitter.png'; 

import { X3 } from "../../icons/X3";

import { useWallet } from "@solana/wallet-adapter-react";
import "./style.css";



export const InitalPage = () => {
  const screenWidth = useWindowWidth();
  const { publicKey } = useWallet();

  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const [isShared, setIsShared] = useState(false);

  // error
  const [errorMessage, setErrorMessage] = useState("");




  // discord
  const joinDiscord = () => {
    window.open('https://discord.gg/zcABQBzRaz', '_blank');
  };


  // twitter share
  const [tweet, setTweet] = useState("I’ve just claimed my Goober username! 🚀 Join the battle and follow @goobergoo_ to secure an early supporter $Goo airdrop. Claim yours now: ");

 // Twitter share
  const tweetUrl = "https://username.goober.wtf";
  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweet)}&url=${encodeURIComponent(tweetUrl)}`;
  ;
  const handleShareClick = () => {
    window.open(twitterUrl, '_blank');
    setIsShared(true);

  };

  const handleTweetChange = (e) => {
    setTweet(e.target.value);
  };

  const handleRegister = async () => {
    // console.log("Register button clicked");

    if (!publicKey) {
      setErrorMessage("Please connect your wallet.");
      return;
    }

    try {
      // console.log("Attempting to register user with wallet address:", publicKey.toString(), "and username:", username);
      const result = await registerUser(publicKey.toString(), username);
      setMessage("Registration successful!");
      setErrorMessage("");
      // console.log("Registration successful!", result);


      // 执行后续操作
      setIsRegistered(true);
    } catch (error) {
      setErrorMessage(error.message);

      console.error("Registration failed:", error.message);
    }
  };

  const handleUsernameChange = async (e) => {
    // console.log("user name changed");
    const newUsername = e.target.value;
    setUsername(newUsername);
    setErrorMessage("");

    // 如果用户名为空，则清除错误消息并返回
    if (newUsername === "") {
      setErrorMessage("");
      return;
    }

    // 动态验证用户名合法性
    const { isValid, message: validMessage } = isValidUsername(newUsername);

    if (!isValid) {
      // console.log("user name not valid");
      // console.log(validMessage);
      setErrorMessage(validMessage);
      return;
    }

    // 检查用户名是否已存在
    if (publicKey) {
      try {
        const exists = await checkUsernameExists(newUsername);
        if (exists) {
          setErrorMessage("This username is already taken. Please try another one.");
        }
      } catch (error) {
        console.error("Error checking username existence:", error);
      }
    }
  };

  useEffect(() => {

    if(!publicKey){
      setIsRegistered(false);
      setIsShared(false);
      setErrorMessage("");
    }

    const checkUser = async () => {
      // console.log("checkUser function run");
      if (publicKey) {
        // console.log("publicKey valid:", publicKey.toString());
        try {
          const exists = await checkUsernameExists(publicKey.toString());
          // console.log("checkUsernameExists result:", exists);
          if (exists) {

            setMessage("This wallet address already has a username.");
            setIsRegistered(true);
            setIsShared(true);

            // 获取用户名
            const userData = await getUsername(publicKey.toString());
            setUsername(userData.username); 
          }
        } catch (error) {
          console.error("Error checking username existence:", error);
        }
      } else {
        // console.log("publicKey not available");
      }
    };

    checkUser();
  }, [publicKey]);

  return (
    <div className="inital-page">
      <div
        className="div-5"
        style={{
          height: screenWidth < 786 ? '100vh' : screenWidth >= 786 ? '100vh' : undefined,
          position: screenWidth < 786 ? 'relative' : undefined,
          width: screenWidth < 786 ? '100vw' : screenWidth >= 786 ? '100vw' : undefined,
        }}
      >
        {screenWidth < 786 && (
          <>
            <div className="overlap-3">
              <img
                className="rectangle-6"
                alt="Rectangle"
                src="https://cdn.animaapp.com/projects/6653c4b266313a614d4fa54c/releases/6653c4d04b4422ee4cb52442/img/rectangle-455.svg"
              />
              <div className='main-component-container'>
                <div className="frame-28">
                  <div className="group-wrapper">
                      <div className="group-19">
                        <div className="frame-29">
                          <div className="text-wrapper-24">Limited Time</div>
                        </div>
                      </div>
                  </div>
                  <div className="frame-30">
                    <p className="claim-your-GOO-4">
                      <span className="text-wrapper-25">Claim Your </span>
                      <span className="text-wrapper-26">GOO </span>
                      <span className="text-wrapper-25">Username Now</span>
                    </p>
                    <p className="text-wrapper-27">Claim today for earlier supporter airdrop! 🎁</p>
                  </div>
                </div>
                {isRegistered?( 
                  isShared?(
                    <div className="frame-9">

                      <div className="frame-10">
                        <p className="text-wrapper-15">
                          🎉 Success! You have successfully secured your username. Welcome to the Goober world! Join our
                          Discord to meet your comrades！
                        </p>
                      </div>

                      {publicKey && (
                      <div className="user-name-wrapper">{username}</div>
                      )}
                      {publicKey && (
                      <div className="text-wrapper-96">{publicKey.toString()}</div>
                      )}
                      <div className="wallet-2" onClick={joinDiscord}>
                        <div className="frame-11">
                          <img
                            className="discord-2"
                            alt="Discord"
                            src="https://cdn.animaapp.com/projects/6653c4b266313a614d4fa54c/releases/6653c4d04b4422ee4cb52442/img/discord.svg"
                          />
                          <div className="text-wrapper-16">Join Discord Now</div>
                        </div>
                      </div>
                    </div>
                  ): (                
                  <div className="frame-168">
                    <div className="frame-169">
                      <p className="to-fully-secure-your">
                        <span className="text-wrapper-93">
                          To fully secure your in-game username, please share this on Twitter with your friends to qualify
                          for the{" "}
                        </span>
                        <span className="text-wrapper-94">early supporter airdrop!</span>
                      </p>
                      <div className="frame-169">
                        <div className="frame-170">
                          <div className="frame-171">
                            <textarea 
                              className="text-wrapper-95"
                              value={tweet}
                              onChange={handleTweetChange}
                            />
                          </div>
                        </div>
                        {publicKey && (
                          <div className="text-wrapper-96">{publicKey.toString()}</div>
                        )}
                      </div>
                    </div>
                    <div className="wallet-10" onClick={handleShareClick}>
                      <div className="frame-172">
                        <img
                          className={`x-2 property-1-twitter`}
                          src={twitterIcon}
                          alt="Twitter Icon"
                          style={{ width: '18px', height: '18px' }} // Adjust size as needed
                        />
                        <div className="text-wrapper-97">Share it on Twitter</div>
                      </div>
                    </div>
                  </div>
                )

                ): (
                <div className="frame-31">
                  <div className="frame-32">
                    <p className="text-wrapper-28">Reserve your in-game handle now</p>
                    <div className="frame-33">
                      <div className="group-20">
                        <div className="frame-34">
                          <div className="group-21">
                            <div className="group-22">
                              <div className="ellipse-11" />
                            </div>
                            <div className="group-23">
                              <div className="ellipse-11" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-35">
                        <input className="enter-your-name-here"
                                placeholder="Enter your name here..."
                                value={username}
                                onChange={handleUsernameChange}
                                 />
                      </div>
                    </div>
                  </div>

                  {publicKey ? (
                    <>
                      <div className="frame-32">
                        <p className="wallet-address-wrapper">{publicKey.toString()}</p>
                        {errorMessage && (
                        <p className="text-wrapper-80">*{errorMessage}</p>
                        )}
                      </div>
                      <div className={`connect-wallet-to-wrapper ${errorMessage ? 'disabled' : ''}`} onClick={!errorMessage ? handleRegister : null}>
                        <div className="connect-wallet-to">Reserve Now</div>
                      </div>
                      </>
                    ) : (
                      // <Wallet
                      //   className="wallet-14"
                      //   divClassName="wallet-15"
                      //   icon={<Iconify property1="wallet-fill" />}
                      //   property1="wallet-connect"
                      //   text="Connect Wallet to Claim"
                      // />
                      <div className='custom-wallet-button-container'>
                        <WalletMultiButton/>
                      </div>
                    )}
                </div>)}
              </div>
              <div className="mobile-iframe-container">
                <iframe
                  width='100%'
                  height='110%'
                  src="https://api.mapbox.com/styles/v1/icedtina/clwjlpqq901rg01qob8z89vlx.html?title=false&access_token=pk.eyJ1IjoiaWNlZHRpbmEiLCJhIjoiY2x3amxsdnlmMGo4bjJqcGtyeTdzd3lnbSJ9.3CDa-_nFcB_XyT0xfZC8-g&zoomwheel=true#0.30/34.8/107.1"
                  title="Outdoors"
                  style={{ border: 'none' }}
                ></iframe>
              </div>
            </div>
            <div className="frame-36">
              <div className="logo-wrapper">
                <div className="logo-3">
                  <div className="group-24">
                    <div className="frame-37">
                      <div className="group-25">
                        <div className="group-26">
                          <div className="ellipse-12" />
                        </div>
                        <div className="group-27">
                          <div className="ellipse-12" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-28">
                    <img
                      className="group-29"
                      alt="Group"
                      src="https://cdn.animaapp.com/projects/6653c4b266313a614d4fa54c/releases/6653c4d04b4422ee4cb52442/img/group-2-2@2x.png"
                    />
                    <div className="group-30">
                      <div className="ellipse-13" />
                    </div>
                    <div className="group-31">
                      <div className="ellipse-14" />
                    </div>
                    <div className="group-32">
                      <div className="overlap-group-4">
                        <div className="ellipse-15" />
                        <div className="rectangle-7" />
                      </div>
                    </div>
                    <img
                      className="subtract-3"
                      alt="Subtract"
                      src="https://cdn.animaapp.com/projects/6653c4b266313a614d4fa54c/releases/6653c4d04b4422ee4cb52442/img/subtract-4.svg"
                    />
                    <div className="frame-38">
                      <div className="rectangle-8" />
                      <div className="ellipse-16" />
                    </div>
                  </div>
                </div>
                <div className='connect-button'>
                  <WalletMultiButton />
                  </div>
              </div>
            </div>
          </>
        )}

        {screenWidth >= 786 && (
          <div className="overlap-4">
             <div className="frame-39">
              <div className="frame-40">
                <div className="frame-41">
                  <div className="logo-4">
                    <div className="group-33">
                      <div className="frame-42">
                        <div className="group-34">
                          <div className="group-35">
                            <div className="ellipse-17" />
                          </div>
                          <div className="group-36">
                            <div className="ellipse-17" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="group-37">
                      <img
                        className="group-38"
                        alt="Group"
                        src="https://cdn.animaapp.com/projects/6653c4b266313a614d4fa54c/releases/6653c67f2404a95919d14329/img/group-2-9@2x.png"
                      />
                      <div className="group-39">
                        <div className="ellipse-18" />
                      </div>
                      <div className="group-40">
                        <div className="ellipse-19" />
                      </div>
                      <div className="group-41">
                        <div className="overlap-group-5">
                          <div className="ellipse-20" />
                          <div className="rectangle-9" />
                        </div>
                      </div>
                      <img
                        className="subtract-4"
                        alt="Subtract"
                        src="https://cdn.animaapp.com/projects/6653c4b266313a614d4fa54c/releases/6653c67f2404a95919d14329/img/subtract-19.svg"
                      />
                      <div className="frame-43">
                        <div className="rectangle-10" />
                        <div className="ellipse-21" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-44">
                    <div className="group-42">
                      <div className="frame-45">
                        <div className="text-wrapper-30">Limited Time</div>
                      </div>
                    </div>
                    <div className="frame-46">
                      <ClaimYourGoo />
                      <p className="text-wrapper-31">Claim today for earlier supporter airdrop! 🎁</p>
                    </div>
                  </div>
                </div>
                {isRegistered?(
                  isShared?(
                <div className="frame-23">
                  <div className="frame-24">
                    <div className="frame-20">
                      <p className="text-wrapper-22">
                        🎉 Success! You have successfully secured your username. Welcome to the Goober world! Join our Discord to meet your comrades！
                      </p>
                    </div>
                  </div>
                  {publicKey && (
                      <div className="web-user-name-wrapper">{username}</div>
                      )}
                      {publicKey && (
                      <div className="wallet-address-wrapper">{publicKey.toString()}</div>
                  )}
                  <div className="frame-25" onClick={joinDiscord}>
                    <div className="frame-26">
                      <div className="frame-27">
                        <img
                          className="discord-3"
                          alt="Discord"
                          src="https://cdn.animaapp.com/projects/6653c4b266313a614d4fa54c/releases/6653c5feeef3dcff0196b913/img/discord-1.svg"
                        />
                        <div className="text-wrapper-23">Join Discord Now</div>
                      </div>
                    </div>
                  </div>
                </div>
                  ):(
                    <div className="modifed-container">
                  <div className="frame-186">
                    <div className="frame-182">
                      <p className="to-fully-secure-your-2">
                        <span className="text-wrapper-103">
                          To fully secure your in-game username, please share this on Twitter with your friends to
                          qualify for the{" "}
                        </span>
                        <span className="text-wrapper-104">early supporter airdrop!</span>
                      </p>
                      <div className="frame-187">
                        <div className="frame-188">
                          <div className="ive-just-claimed-my-wrapper">
                            <textarea 
                              className="ive-just-claimed-my"
                              value={tweet}
                              onChange={handleTweetChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {publicKey && (
                      <div className="text-wrapper-105">{publicKey.toString()}</div>
                    )}
                  </div>
                  <div className="frame-189" onClick={handleShareClick}>
                    <div className="frame-190">
                      <div className="frame-191">

                        <img
                          className={`icons-2`}
                          src={twitterIcon}
                          alt="Twitter Icon"
                          style={{ width: '40px', height: '40px' }} // Adjust size as needed
                        />
                        
                        <div className="text-wrapper-106">Share it on Twitter</div>
                      </div>
                    </div>
                  </div>
                </div>
                  )
                ):(
                <div className='modifed-container'>
                  <div className="frame-44">
                    <p className="text-wrapper-32">Reserve your in-game handle now</p>
                    <div className="frame-48">
                      <div className="frame-49">
                        <div className="frame-50">
                          <div className="group-43">
                            <div className="frame-51">
                              <div className="group-44">
                                <div className="group-45">
                                  <div className="ellipse-22" />
                                </div>
                                <div className="group-46">
                                  <div className="ellipse-22" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <input className="enter-your-name-here"
                                placeholder="Enter your name here..."
                                value={username}
                                onChange={handleUsernameChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {publicKey && (
                    <>
                    <div className="text-wrapper-79">{publicKey.toString()}</div>
                    {errorMessage && (
                        <p className="text-wrapper-80">*{errorMessage}</p>
                    )}
                    </>
                  )}
                  <div className="frame-52">
                    {publicKey ? (
                      <div className={`frame-53 ${errorMessage ? 'disabled' : ''}`} onClick={!errorMessage ? handleRegister : null}>
                          <div className="text-wrapper-33">Reserve Now</div>
                      </div>
                    ) : (
                      // <Wallet
                      //   className="wallet-14"
                      //   divClassName="wallet-15"
                      //   icon={<Iconify property1="wallet-fill" />}
                      //   property1="wallet-connect"
                      //   text="Connect Wallet to Claim"
                      // />
                      <div className='custom-wallet-button-container'>
                        <WalletMultiButton/>
                      </div>
                    )}
                  </div>
                </div>
                )}
              </div>
            </div>
            <div className="iframe-container">
              <iframe
                width='100%'
                height='110%'
                src="https://api.mapbox.com/styles/v1/icedtina/clwjlpqq901rg01qob8z89vlx.html?title=false&access_token=pk.eyJ1IjoiaWNlZHRpbmEiLCJhIjoiY2x3amxsdnlmMGo4bjJqcGtyeTdzd3lnbSJ9.3CDa-_nFcB_XyT0xfZC8-g&zoomwheel=true##2.31/34.8/107.1"
                title="Outdoors"
                style={{ border: 'none' }}
              ></iframe>
            </div>
            <div className="connect-button">
              <WalletMultiButton />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
