import React from "react";
import ReactDOM from 'react-dom';
import { App } from "./App";
import * as web3 from '@solana/web3.js'
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import '@solana/wallet-adapter-react-ui/styles.css';


// Define the endpoint and initialize wallets
const endpoint = web3.clusterApiUrl('devnet')
const wallets = [new PhantomWalletAdapter()]; // Add more wallet adapters if needed

const Main = () => {
    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <App />
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <Main />
    </React.StrictMode>,
    document.getElementById('app')
);