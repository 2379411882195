/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const X3 = ({ className }) => {
  return (
    <svg
      className={`x-3 ${className}`}
      fill="none"
      height="17"
      viewBox="0 0 16 17"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M5.99386 8.75691L0 1.30888H4.74756L8.44753 5.91228L12.4004 1.3296H15.0151L9.71175 7.48519L16 15.3089H11.2666L7.2603 10.3306L2.98317 15.2951H0.354296L5.99386 8.75691ZM11.9565 13.9289L2.91105 2.68887H4.0571L13.0912 13.9289H11.9565Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};
