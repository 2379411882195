// auth.js
import { db } from "./firebase";
import { collection, query, where, getDocs, doc, setDoc, getDoc } from "firebase/firestore";
import { isValidUsername, isValidWalletAddress } from "./validation";  // 确保正确导入


// 检查用户名是否唯一
const isUsernameUnique = async (username) => {
  const q = query(collection(db, "users"), where("username", "==", username));
  const snapshot = await getDocs(q);
  return snapshot.empty;
};

// 注册用户
const registerUser = async (walletAddress, username) => {
  // 检查钱包地址是否合法
  if (!isValidWalletAddress(walletAddress)) {
    throw new Error("Invalid wallet address.");
  }

  // 检查钱包地址是否已注册
  const userDoc = await getDoc(doc(db, "users", walletAddress));
  if (userDoc.exists()) {
    throw new Error("This wallet address already has a username.");
  }

  // 检查用户名是否合法
  const { isValid, message } = isValidUsername(username);
  if (!isValid) {
    throw new Error(message);
  }

  // 检查用户名是否唯一
  const isUnique = await isUsernameUnique(username);
  if (!isUnique) {
    throw new Error("Username already taken");
  }

  // 注册用户
  await setDoc(doc(db, "users", walletAddress), {
    username,
    walletAddress
  });

  return { username, walletAddress };
};

// 登录用户
const loginUser = async (walletAddress) => {
  const userDoc = await getDoc(doc(db, "users", walletAddress));
  if (!userDoc.exists()) {
    throw new Error("User not found");
  }

  return userDoc.data();
};

const checkUsernameExists = async (walletAddress) => {
  const userDoc = await getDoc(doc(db, "users", walletAddress));
  return userDoc.exists();
};

const getUsername = async (walletAddress) => {
  const userDocRef = doc(db, "users", walletAddress);
  const userDoc = await getDoc(userDocRef);
  
  if (userDoc.exists()) {
    return userDoc.data(); // 返回文档数据
  } else {
    throw new Error("This wallet address hasn't registered.");
  }
};

export { registerUser, loginUser, checkUsernameExists, getUsername };
